import { Controller } from "@hotwired/stimulus";
import cytoscape from "cytoscape";
import dagre from "cytoscape-dagre";
import nodeHtmlLabel from "cytoscape-node-html-label";

cytoscape.use(nodeHtmlLabel);
cytoscape.use(dagre);

export default class extends Controller {
  static values = {root: Object};

  connect() {
    this.initializeGraph()
  }

  initializeGraph() {
    // Initialize Cytoscape with extensions
    cytoscape.use(nodeHtmlLabel);

    this.cy = cytoscape({
      container: this.element,
      style: [
        {
          selector: "node",
          style: {
            width: 450,
            height: 260,
            shape: "rectangle",
            "background-color": "#fff",
            "border-width": 1,
            "border-color": "#999",
            "text-valign": "center",
            "text-halign": "center",
          },
        },
        {
          selector: "edge",
          style: {
            width: 2,
            "line-color": "#999",
            "target-arrow-color": "#999",
            "target-arrow-shape": "triangle",
            "curve-style": "bezier",
          },
        },
      ],
    });

    // Add nodes and edges to the graph
    const graphData = this.rootValue;
    this.cy.add(graphData.nodes);
    this.cy.add(graphData.edges);

    // Update the node HTML label configuration
    this.cy.nodeHtmlLabel([
      {
        query: "node",
        tpl(data) {
          return `
            <div class="node-content-wrapper" 
                 data-node-id="${data.id}"
                 style="width: 100%; display: block; position: relative; pointer-events: all;">
              <div class="node-content" style="pointer-events: all;">${data.html}</div>
            </div>
          `;
        },
      },
    ], {
      enablePointerEvents: true
    });

    // Disable pointer events on the canvas
    this.cy.ready(() => {
      const canvas = this.cy.container().querySelector("canvas");
      if (canvas) {
        canvas.style.pointerEvents = "none";
      }
    });

    // Use Dagre layout for an inverted tree structure
    const layout = this.cy.layout({
      name: "dagre",
      rankDir: "TB",
      animate: true,
      animationDuration: 1000,
      animationEasing: "ease-in-out",
      spacingFactor: 1.5,
    });

    layout.run();

    // Fit the graph to the container
    this.cy.fit();

    this.handleResize = () => this.cy.fit();
    window.addEventListener("resize", this.handleResize);
  }

  disconnect() {
    if (this.cy) {
      this.cy.destroy()
    }
    window.removeEventListener("resize", this.handleResize);
  }
}
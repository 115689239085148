import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dropdown", "field"];

  connect() {
    if (this.hasDropdownTarget) {
      this.update(); // Initialize visibility when the controller connects
      $(this.dropdownTarget).on('change', (e) => {
        this.update();
      });
    }
  }

  update() {

    const selectedValue = this.dropdownTarget.value;

    this.fieldTargets.forEach((field) => {
      const valuesAttr = field.dataset.toggleFieldValues;

      // Handle null or undefined valuesAttr gracefully
      if (!valuesAttr) {
        field.hidden = true;
        return;
      }

      const values = valuesAttr.split(",").map((v) => v.trim());
      field.hidden = !values.includes(selectedValue); // Toggle visibility
    });
  }

}

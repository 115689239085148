import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="dependant-select"
export default class extends Controller {

  static values = {
    url: String,
    paramName: String
  }

  static targets = ["destination", "source"]
  connect() {
    console.log("Connecting the controller")
    this.initializeSourceSelect()
  }

  initializeSourceSelect() {
    const sourceSelect = $(this.sourceTarget)

    const selectedValue = sourceSelect.val();
    sourceSelect.on('change', (event) => this.handleChange(event))
    if (selectedValue) {
      this.updateDestination(selectedValue)
    }

  }

  handleChange(event) {
    const selectedValue = event.target.value
    this.updateDestination(selectedValue)
  }

  updateDestination(selectedValue) {


    const url = `${this.urlValue}?${this.paramNameValue}=${selectedValue}`

    const targetElem = $(this.destinationTarget)

    fetch(url).then(
        (response) => response.json()
    ).then((json) => {
      const selected_value = targetElem.val();
      targetElem.empty();
      const {values} = json;
      values.forEach((option) => {
        const selected = option.value == selected_value
        const o = new Option(option.display, option.value, selected, selected);
        targetElem.append($(o))
      });
      targetElem.material_select();


    }).catch(error => {
      alert(error);
      console.error('Error updating select:', error)
    })

  }

  disconnect() {
  }
}
